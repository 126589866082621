import React from 'react';
import "./Sponcers.css";

function Sponcers() {
    return (
        <div className = "sponcers">
            <h1 className = "sponcers__h1">Sponsored & Supported by:</h1>
            <div className = "sponcers__logo">
                
            </div>
        </div>
    )
}

export default Sponcers
